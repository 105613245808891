export default {
  path: '/',
  name: 'account',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'role',
      name: 'role',
      meta: { title: '角色管理', permission: 'role' },
      component: () => import('@/views/role/Role'),
    },
    {
      path: "noticeCreate",
      meta: { title: "创建通告", permission: "noticeCreate" },
      component: () => import("@/views/account/NoticeFormModal"),
    },
     {
      path: "noticeSet",
      meta: { title: "公告设置", permission: "noticeSet" },
      component: () => import("@/views/account/noticeSet.vue"),
    },
    {
      path: 'account',
      name: 'account',
      meta: { title: '员工账号', permission: 'account' },
      component: () => import('@/views/account/Account'),
    },
    {
      path: 'config',
      name: 'config',
      meta: { title: '系统配置', permission: 'config' },
      component: () => import('@/views/config/Config'),
    },
    {
      path: 'system/user_log',
      name: 'user_log',
      meta: { title: '系统配置', permission: 'user_log' },
      component: () => import('@/views/user_log/user_log'),
    },
  ],
}