import request from '@/utils/request';


// Role
export function roleList(params) {
  return request({ url: `/roles/`, method: 'get', params })
}

export function roleCreate(data) {
  return request({ url: `/roles/`, method: 'post', data })
}

export function roleUpdate(data) {
  return request({ url: `/roles/${data.id}/`, method: 'put', data })
}

export function roleDestroy(data) {
  return request({ url: `/roles/${data.id}/`, method: 'delete', data })
}


// User
export function userList(params) {
  return request({ url: `/users/`, method: 'get', params })
}

export function userCreate(data) {
  return request({ url: `/users/`, method: 'post', data })
}

export function userUpdate(data) {
  return request({ url: `/users/${data.id}/`, method: 'put', data })
}

export function userDestroy(data) {
  return request({ url: `/users/${data.del_id}/`, method: 'delete', data })
}

export function userResetPassword(data) {
  return request({ url: `/users/${data.id}/reset_password/`, method: 'post', data })
}

export function userImages(data) {
  return axios({
    url: '/api/user_images/',
    headers: { 'X-CSRFToken': Cookies.get('csrftoken'), Authorization: 'Bearer ' + Cookies.get('access') },
    method: 'post',
    data,
  })
}
//公告number获取
export function noticeNumber(params) {
  return request({ url: `/notice/number/`, method: 'get', params })
}
//发布公告
export function noticeCreate(data) {
  return request({ url: `/notice/`, method: 'post', data })
}
//获取所有的公告
export function noticeList(params) {
  return request({ url: `/notice/person_notice/`, method: 'get', params })
}
//获取所有的系统公告
export function noticeSystemList(params) {
  return request({ url: `/notice/system_announcement`, method: 'get', params })
}
//获取所有的公告
export function noticeListSet(params) {
  return request({ url: `/notice/notice_list/`, method: 'get', params })
}
//获取所有的系统公告
export function noticeListSystemSet(params) {
  return request({ url: `/notice/notice_list_system/`, method: 'get', params })
}
//顶置公告
export function noticeListSetTop(data) {
  return request({ url: `/notice/${data.id}/notice_list_top/`, method: 'post', data })
}