
export default {
  // namespaced:true,
  state: () => ({
    username: '',
    isManager: false, // team 管理
    isAdmin: false, // 超级管理
    isDept: false, // 部门管理
    permissions: [],
    config: {},
    barConfig: {},
    currentWarehouse: undefined,
    id: '',
    approval:[],
    homeTab:false
  }),
  mutations: {
    setHomeTab(state,item){
      // console.log('设置了状态')
      state.homeTab=item
      // console.log('当前状态',state.homeTab)
    },
    setUser(state, item) {
      state.username = item.username;
      state.isManager = item.is_manager;
      state.isAdmin = item.is_admin;
      state.isDept = item.is_dept;
      state.id = item.id;
      const {
        jsonpermissions
      } = item;

      // console.log('item',item);
      if (jsonpermissions && jsonpermissions.length) {
        let permissions = []
        for (let jsonItem of jsonpermissions) {
          permissions = [...JSON.parse(jsonItem.value), ...permissions]
        }
        state.permissions = [...new Set(permissions)]
      } else {
        state.permissions = []
      }
      // state.permissions = item.permissions;
      state.barConfig = {
        batchLabelLength: item.batch_label_length,
        batchLabelWidth: item.batch_label_width,
        locationLabelLength: item.location_label_length,
        locationLabelWidth: item.location_label_width,
        materialLabelLength: item.material_label_length,
        materialLabelWidth: item.material_label_width,
        palletLabelLength: item.pallet_label_length,
        palletLabelWidth: item.pallet_label_width,
      }
      if (item.configs) {
        for (let config of item.configs) {
          state.config[config.type] = config.strategy;
        }
      }
    },
    setWarehouse(state, value) {
      state.currentWarehouse = value;
    },
    setBarConfig(state, value) {
      state.barConfig = {
        batchLabelLength: value.batch_label_length,
        batchLabelWidth: value.batch_label_width,
        locationLabelLength: value.location_label_length,
        locationLabelWidth: value.location_label_width,
        materialLabelLength: value.material_label_length,
        materialLabelWidth: value.material_label_width,
        palletLabelLength: value.pallet_label_length,
        palletLabelWidth: value.pallet_label_width,
      }
    },
  },
}