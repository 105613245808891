// 监听浏览器关闭时的一个时间戳，表示浏览器关闭的时间
import {userUpdate} from "@/api/account";
import Cookies from "js-cookie";

window.addEventListener("beforeunload", () => {
  const closeTime = Date.now();
  localStorage.setItem("closeTime", closeTime);
});

// 定义一个检查浏览器关闭之后到重新打开浏览器的时间，如果超时就清理浏览器缓存，如果没超时，则使用之前的缓存。 lastActivityTime
function checkTimeOut() {
  //登录进来的初始时间
  const closeTime = localStorage.getItem('closeTime');
  if(closeTime) {
    const now = Date.now();
    const twoHours = 2*60*60*1000;//两小时为浏览器时间到期期限
    if(now - closeTime > twoHours) {
      // 超过两小时,清理浏览器缓存
       const data = {
            id: Cookies.get("id"),
            name: Cookies.get("name"),
            username: Cookies.get("username"),
            is_login: false,
          };
        const config = {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          Authorization: "Bearer " + Cookies.get("access"),
        },
      };
       userUpdate(data, config).then((data) => {
            Cookies.remove("access");
            Cookies.remove("refresh");
            Cookies.remove("id");
            Cookies.remove("name");
            Cookies.remove("username");
            localStorage.setItem("activeItem", JSON.stringify("null"));
            localStorage.removeItem('csrftoken');
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            window.location.href = "/user/sign-in";
          }).finally(()=>{
            window.alert('由于长时间未操作，登录超时，请重新登录')
       });

    }
  }
}
// 可以使用requestAnimationFrame不断检查活动状态
(function loop() {
  // 当前路由是登录页面那就不执行
  if(localStorage.getItem('is_login')==='false'){
    checkTimeOut();
  }else{
  }
    requestAnimationFrame(loop);
})();
// document.addEventListener('DOMContentLoaded', checkTimeOut)
