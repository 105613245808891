export default {
  path: "/goods",
  name: "goods",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/finance/classification",
  children: [
    {
      path: "classification",
      meta: { title: "产品分类", permission: "classification", keepAlive:false },
      component: () => import("@/views/goods/classification/index"),
    },
    {
      path: "unit",
      meta: { title: "产品单位", permission: "unit", keepAlive:false },
      component: () => import("@/views/goods/unit/index"),
    },
    {
      path: "applicationDirection",
      meta: { title: "应用方向", permission: "applicationDirection", keepAlive:false},
      component: () => import("@/views/goods/applicationDirection/index")
    },
    {
      path: "brand",
      meta: { title: "品牌管理", permission: "brand", keepAlive:false },
      component: () => import("@/views/goods/brand/index"),
    },
    {
      name: 'information',
      path: "information",
      meta: { title: "产品信息", permission: "information", keepAlive:false },
      component: () => import("@/views/goods/information/index"),
    },
    {
      path: "temporary_warning",
      meta: { title: "临期预警", permission: "temporary_warning" },
      component: () => import("@/views/goods/temporaryWarning/index"),
    },
    {
      name: 'combo',
      path: "combo",
      meta: { title: "产品套餐", permission: "combo" },
      component: () => import("@/views/goods/combo/index"),
    },
    {
      name: 'combo_create',
      path: "combo_create",
      meta: { title: "套餐创建", permission: "combo_create" ,notMenu: true },
      component: () => import("@/views/goods/comboCreate/index"),
},
  ],
};
